<template>
  <TableWithFooter
    class="top-gap"
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="auto">
        <SortButton v-model="sortDesc" />
        <v-checkbox
          v-model="allSelected"
          class="mt-0"
          hide-details
        />
      </v-col>
      <v-col cols="3">
        {{ $t('dataPoints.label_group') }}
      </v-col>
      <v-col cols="3">
        {{ $t('models.labels') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        cols="2"
      >
        {{ $t('datatable.header.precision') }}
      </v-col>
      <v-col cols="3">
        {{ $t('dataPoints.model') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <div
          v-for="item in pages[currentPage]"
          :key="item.id"
        >
          <v-row
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee !important"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="$emit('setItems', [...allItems])"
                hide-details
              />
            </v-col>
            <v-col cols="3">
              <ItemName
                :key="item.id"
                :ref="`name_${item.id}`"
                style="z-index: 202"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :clickable="!disabled"
                @save-file-name="(id, newName) => {item.name = newName; saveGroupName(id, newName);}"
                @name-click="handleEditButton($route.params.id, item.id, item.category)"
              />
            </v-col>
            <v-col cols="3">
              <div
                v-if="item.labels && item.labels.length > 0"
                style="overflow: hidden; white-space: nowrap;"
              >
                <MaxWidthChip
                  class="mr-1 mb-0"
                  :text-array="[item.labels[0].label || item.labels[0]]"
                  small
                />
                <div
                  v-if="item.labels.length > 1"
                  class="inline-middle"
                >
                  <MaxWidthChip
                    class="mr-1 mb-0"
                    :text-array="[`+${item.labels.length - 1}`]"
                    small
                  />
                  <v-tooltip
                    v-if="expanded.length === 0 || expanded[0].id !== item.id"
                    color="#423F4F"
                    right
                  >
                    <template #activator="{ props }">
                      <v-icon
                        class="clickable"
                        size="16"
                        v-bind="props"
                        @click="expanded = [item]"
                      >
                        fas fa-chevron-right
                      </v-icon>
                    </template>
                    <span style="color: white">
                      {{ $t('show_all') }}
                    </span>
                  </v-tooltip>
                  <v-icon
                    v-else
                    class="clickable"
                    size="16"
                    @click="expanded = []"
                  >
                    fas fa-chevron-down
                  </v-icon>
                </div>
              </div>
              <div v-else>
                <MaxWidthChip
                  class="flex-shrink-0 mb-0"
                  color="#999999"
                  :text-array="[$tc('datatable.header.none', 2)]"
                />
              </div>
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              cols="2"
            >
              <MaxWidthChip
                v-if="item.nb_valid_values + item.nb_invalid_values > 0"
                class="mr-1 mb-0"
                :text-array="[displayVerificationResult(item.nb_valid_values, item.nb_invalid_values)]"
              />
              <span v-else>
                —
              </span>
              <div
                v-if="item.precision_by_label && Object.keys(item.precision_by_label).length > 1"
                class="inline-middle"
              >
                <v-tooltip
                  v-if="expanded.length === 0 || expanded[0].id !== item.id"
                  color="#423F4F"
                  right
                >
                  <template #activator="{ props }">
                    <v-icon
                      class="clickable"
                      size="16"
                      v-bind="props"
                      @click="expanded = [item]"
                    >
                      fas fa-chevron-right
                    </v-icon>
                  </template>
                  <span style="color: white">
                    {{ $t('show_all') }}
                  </span>
                </v-tooltip>
                <v-icon
                  v-else
                  class="clickable"
                  size="16"
                  @click="expanded = []"
                >
                  fas fa-chevron-down
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3">
              <ItemName
                v-if="item.custom_model_name"
                :item="{ name: `${item.custom_model_name} (v${item.custom_model_version})` }"
                :clickable="false"
                :editing-allowed="false"
                :show-id="false"
              />
              <span v-else-if="item.is_generative">
                {{ $t('dataPoints.generative.title') }}
              </span>
              <span v-else>
                —
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="expanded.length > 0 && expanded[0].id === item.id"
            class="table-row"
            style="background-color: rgb(var(--v-theme-grey-darken1))"
          >
            <v-col cols="3" />
            <v-col
              cols="2"
              class="flex-wrap pt-3"
              style="margin-left: 84px"
            >
              <div
                v-for="label in item.labels"
                :key="label.id"
              >
                <MaxWidthChip
                  class="flex-shrink-0"
                  :text-array="[label.label || label]"
                  show-full
                  small
                />
              </div>
            </v-col>
            <v-col
              v-if="!isEmpty(item.precision_by_label)"
              class="flex-wrap"
              cols="2"
            >
              <div
                v-for="labelName in item.labels"
                :key="labelName"
                style="height: 38px"
              >
                <MaxWidthChip
                  v-if="item.precision_by_label && labelName in item.precision_by_label"
                  class="flex-shrink-0"
                  :text-array="[
                    displayVerificationResult(
                      item.precision_by_label[labelName.label || labelName].valid,
                      item.precision_by_label[labelName.label || labelName].invalid
                    )
                  ]"
                  show-full
                />
                <MaxWidthChip
                  v-else
                  class="flex-shrink-0"
                  :text-array="['—']"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import { http } from '@/plugins/axios';
import { isEmpty } from 'lodash';

export default {
  name: 'LabelGroupsTable',

  components: {
    ItemName,
    TableWithFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      expanded: [],
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    sortDesc: {
      get() {
        return this.$store.getters.lgSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setLgSortDesc', sortDesc);
      },
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.allItems.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.$emit('setItems', [...selected]);
      }
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.$emit('getItems', desc);
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    isEmpty,
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    handleEditButton(id, itemId, category) {
      if (!this.disabled) {
        if (category && category === 'generative') {
          this.$router.push({
            name: 'DatapointConfig',
            params: { id, itemId },
          });
        } else {
          this.$router.push({
            name: 'LabelgroupConfig',
            params: { id, itemId },
          });
        }
      }
    },

    async saveGroupName(id, name) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.put(`system_2/extraction_groups/${id}`, { name });
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.allSelected = false;
        this.$emit('saveName');
      } catch (error) {
        this.docLoading = false;
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    displayVerificationResult(validValues, invalidValues) {
      return `${(validValues / (validValues + invalidValues) * 100).toFixed(0)}% \
        (${validValues}/${validValues + invalidValues})`
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['saveName', 'setItems', 'getItems', 'copyGenerative'],
}
</script>
